<template>
  <div class="home">
  <highcharts :options="chartOptions"></highcharts>

    <header>
      <h3>Users</h3>
      <div class="buttons">
        <button style="background-color: #42b983; border: none; color: white" @click="exportData">Export</button>
        <button :class="{isSelected: visitFilter === true}" @click="() => setVisitFilter(true)">Has visited</button>
        <button :class="{isSelected: visitFilter === false}" @click="() => setVisitFilter(false)">Has Not Visited</button>
        <button :class="{isSelected: groupFilter == 4}" @click="() => setGroupFilter(4)">Middle-East</button>
        <button :class="{isSelected: groupFilter == 6}" @click="() => setGroupFilter(6)">Europe</button>
        <button :class="{isSelected: groupFilter == 8}" @click="() => setGroupFilter(8)">America</button>
        <button :class="{isSelected: groupFilter == 12}" @click="() => setGroupFilter(12)">Siege</button>
      </div>
    </header>
    <p>Nb with current filter: {{usersToDisplay.length}}</p>
    <div class="users">
      <router-link tag="div" :to="'/users/' + user.id" class="user" v-for="user in usersToDisplay" :key="user.id">
        <p>{{ user.id }} - {{ user.email }}</p>
        <p class="visit" :class="{visited: user.hasSessions == true, notvisited: user.hasSessions == false}">{{ user.hasSessions ? ' has visited the site' : ' has not visited the site' }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Highcharts from 'highcharts'
export default {
  name: 'Home',
  data(){
    return {
      users: [],
      usersObj: {},
      usersToDisplay: [],
      presences: null,
      presencesData: {
        labels: [],
        data: []
      },
      chartOptions:{
        chart: {
          zoomType: 'x'
        },
        title: {
          text: 'Users on Guerlain (world site)'
        },
        subtitle: {
          text: document.ontouchstart === undefined ?
              'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: 'Nb of users'
          }
        },
        legend: {
          enabled: false
        },
        plotOptions: {
          area: {
            fillColor: {
              linearGradient: {
                x1: 0,
                y1: 0,
                x2: 0,
                y2: 1
              },
              stops: [
                [0, Highcharts.getOptions().colors[0]],
                [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
              ]
            },
            marker: {
              radius: 2
            },
            lineWidth: 1,
            states: {
              hover: {
                lineWidth: 1
              }
            },
            threshold: null
          }
        },

        series: [{
          type: 'area',
          name: 'Users',
          data: []
        }]
      },
      groups: {
        4: 'Middle-East',
        6: 'Europe',
        8: 'America',
        12: 'Siege'
      },
      visitFilter: null,
      groupFilter: null
    }
  },
  async mounted(){

    this.users = this.$store.getters.users
    this.usersObj = this.$store.getters.usersObj

    this.presences = this.$store.getters.analytics.presences
    for(let presence of this.presences){
      this.presencesData.data.push([
          presence.ts * 1000,
          presence.nbUser
      ])
      console.log(presence.ts * 1000)
    }

    this.usersToDisplay = this.users

    this.chartOptions.series[0].data = this.presencesData.data
  },
  methods: {
    setVisitFilter(filter){
      if(this.visitFilter == filter){
        this.visitFilter = null
      }
      else {
        this.visitFilter = filter
      }
      this.filter()
    },
    setGroupFilter(filter){
      if(this.groupFilter == filter){
        this.groupFilter = null
      }
      else {
        this.groupFilter = filter
      }
      this.filter()
    },
    filter(){
      this.usersToDisplay = []
      for(let user of this.users){
        if(this.visitFilter != null){
          if(user.hasSessions == this.visitFilter){
            if(this.groupFilter != null){
              if(user.group_id == this.groupFilter){
                this.usersToDisplay.push(user)
              }
            }
            else {
              this.usersToDisplay.push(user)
            }
          }
        }
        else {
          if(this.groupFilter != null){
            if(user.group_id == this.groupFilter){
              this.usersToDisplay.push(user)
            }
          }
          else {
            this.usersToDisplay.push(user)
          }
        }

      }
    },
    async exportData(){
      const { data } = await this.$axios.get('/export.php')
      window.location = data
    }
  }
}
</script>

<style lang="scss" scoped>

  .user {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 15px;
    border: solid 1px #BEBEBE;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .user:hover {
    background-color: #F1F1F1;
  }

  .visit {
    padding: 5px 10px;
    border-radius: 10px;
    color: white;
    margin-left: 20px;
  }

  .visited {
    background-color: #2cb44a;
  }

  .notvisited {
    background-color: #ff5d58;
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      align-items: center;

      button {
        border: solid 1px black;
        padding: 10px 15px;
        border-radius: 5px;
        font-weight: bold;
        background-color: transparent;
        margin-left: 10px;

        &.isSelected {
          background-color: black;
          color: white;
        }
      }
    }
  }

</style>
