import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    analytics: null,
    users: null,
    usersObj: null
  },
  getters: {
    analytics: (state, getters) => {
      return state.analytics
    },
    users: (state, getters) => {
      return state.users
    },
    usersObj: (state, getters) => {
      return state.usersObj
    }
  },
  mutations: {
    ANALYTICS: (state, payload) => {
      state.analytics = payload
    },
    USERS: (state, payload) => {
      state.users = payload
    },
    USERSOBJ: (state, payload) => {
      state.usersObj = payload
    }
  },
  actions: {
    setAnalytics: ({commit}, payload) => {
      commit('ANALYTICS', payload)
    },
    setUsers: ({commit}, payload) => {
      commit('USERS', payload)
    },
    setUsersObj: ({commit}, payload) => {
      commit('USERSOBJ', payload)
    }
  },
  modules: {
  }
})
