import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios";
import moment from "moment";

import HighchartsVue from 'highcharts-vue'

Vue.use(HighchartsVue)

Vue.config.productionTip = false

axios.defaults.baseURL = 'https://guerlain-world-stats.blackmoonlab.com/';

Vue.prototype.$axios = axios
moment.locale('en-gb');
Vue.prototype.$moment = moment

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
