<template>
  <div class="users">
    <header>
      <p class="backButton" @click="() => $router.back()">BACK</p>
      <h2>{{ $store.getters.usersObj[$route.params.id].email }}</h2>
    </header>
    <h3>Sessions</h3>
    <div class="sessions">
      <div class="session" v-for="session in sessions" :key="$route.params.id + session.start">
        <p><span class="start">Start</span>: {{ session.start }}</p>
        <p><span class="end">End</span>: {{ session.end }}</p>
      </div>
    </div>
    <br>
    <br>
    <h3>Video watchtime</h3>
    <div class="videos">
      <div class="video" v-for="ve in videoevents" :key="ve.title">
        <p>{{ ve.title }}</p>
        <p>{{ ve.completion }}%</p>
        <p>{{ ve.ts }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Users",
  data(){
    return {
      codeToVideo: {
        'me-brand': 'Brand vision & New Retail strategy',
        'me-jenny': 'Business review & Team’s messages for you! - Middle East',
        'me-oil': 'New Abeille Royale Advanced Youth Watery Oil',
        'ehr-brand': 'Brand vision & New Retail strategy',
        'ehr-penelope': 'Business review & Team’s messages for you! - Europe',
        'ehr-oil': 'New Abeille Royale Advanced Youth Watery Oil',
        'a-brand': 'Brand vision & New Retail strategy',
        'a-oil': 'New Abeille Royale Advanced Youth Watery Oil',
        'ru-brand': 'Brand vision & New Retail strategy',
        'ru-penelope': 'Business review & Team’s messages for you! - Europe ',
        'ru-oil': 'New Abeille Royale Advanced Youth Watery Oil',
        's-brand': 'Brand vision & New Retail strategy',
        's-jenny': 'Business review & Team’s messages for you! - Middle East',
        's-oil': 'New Abeille Royale Advanced Youth Watery Oil',
        's-penelope': 'Business review & Team’s messages for you! - Europe',
        'test': 'Video used for testing purpose'
      }
    }
  },
  computed: {
    sessions(){
      let sessionsRaw = this.$store.getters.analytics.sessions[this.$route.params.id]
      let sessions = []
      if(sessionsRaw != undefined){
        for(let session of sessionsRaw){
          session.start = this.$moment(session.start * 1000).format('LLL')
          session.end = this.$moment(session.end * 1000).format('LLL')
          sessions.push(session)
        }
      }
      return sessions
    },
    videoevents(){

      if(this.$store.getters.analytics['users-videoevents'][this.$route.params.id] == undefined){
        return {}
      }

      let videoeventsRaw = this.$store.getters.analytics['users-videoevents'][this.$route.params.id].details

      const videoEvents = {}

      if(videoeventsRaw != undefined){
        for(let ve of videoeventsRaw) {
          console.log(ve)
          if (videoEvents[ve.name] == undefined) {
            videoEvents[ve.name] = {
              ts: this.$moment(ve.timestamp * 1000).format('LLL'),
              completion: ve.completion,
              title: this.codeToVideo[ve.name]
            }
          }
          else {
            if(Number(videoEvents[ve.name].completion) < Number(ve.completion)){
              videoEvents[ve.name] = {
                ts: this.$moment(ve.timestamp * 1000).format('LLL'),
                completion: ve.completion,
                title: this.codeToVideo[ve.name]
              }
            }
          }
        }
      }

      return videoEvents

    }
  }
}
</script>

<style lang="scss" scoped>
  .session {
    display: flex;
    align-items: center;
    padding: 5px;
    border: solid 1px #BEBEBE;
    border-radius: 15px;
    margin-bottom: 10px;

    .end {
      color: #ff5d58;
      margin-left: 20px;
    }

    .start {
      color: #42b983;
    }
  }

  header {
    display: flex;
    align-items: center;

    p {
      margin-right: 30px;
    }

    h2 {
      margin: 0;
    }
  }

  .backButton {
    font-weight: bold;
    cursor: pointer;
    color: white;
    background-color: black;
    padding: 10px 15px;
    border-radius: 10px;
  }

  .video {
    display: flex;
    align-items: center;
    padding: 5px;
    border: solid 1px #BEBEBE;
    border-radius: 15px;
    margin-bottom: 10px;

    p {
      margin-right: 30px;
    }
  }
</style>